.downloads {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.downloads-header h2 {
  font-size: 70px;
  font-weight: 500;
}

.downloads-icon {
  font-size: 170px;
}

.downloads-imgs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 120px;
}

.downloads-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.downloads-img {
  margin-right: 90px;
}

.downloads-katalog {
  width: 180px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.14);
}

.downloads-katalog-lm {
  width: 220px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.14);
}

.downloads-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  font-size: 28px;
}

.mt-1 {
  margin-top: 40px;
}

.mt-2 {
  margin-top: 100px;
}

@media only screen and (max-width: 480px) {
  .downloads-header h2 {
    font-size: 34px;
  }

  .download-down {
    height: 140px;
    width: 100px;
  }

  .downloads-wrapper {
    flex-direction: column;
    margin: auto;
  }

  .downloads-img {
    margin-right: 10px;
  }

  .mt-2 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .downloads-header h2 {
    font-size: 34px;
  }

  .download-down {
    height: 140px;
    width: 100px;
  }

  .downloads-wrapper {
    flex-direction: column;
    margin: auto;
  }

  .downloads-img {
    margin-right: 10px;
  }

  .mt-2 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .downloads-header h2 {
    font-size: 52px;
  }

  .download-down {
    height: 160px;
    width: 120px;
  }

  .downloads-wrapper {
    flex-direction: column;
    margin: auto;
  }

  .downloads-img {
    margin-right: 10px;
  }

  .mt-2 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .downloads-header h2 {
    font-size: 58px;
  }

  .download-down {
    height: 190px;
    width: 140px;
  }

  .downloads-img {
    margin-right: 60px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .downloads-header h2 {
    font-size: 48px;
  }

  .download-down {
    height: 170px;
    width: 130px;
  }

  .downloads-img {
    margin-right: 60px;
  }
}
