.katalog {
  background-image: url(../../images/tempelhagens.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right bottom !important;
  background-size: cover;
  height: 40vh;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 80px;
  position: relative;
  overflow: visible;
}

.katalog-over {
  background: rgb(57, 86, 147, 0.8);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.katalog-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  color: #fff;
  overflow: visible;
}

.katalog-kat {
  display: flex;
  flex: 2.5;
  position: relative;
  justify-content: flex-end;
}

.katalog-image {
  width: 277px;
  margin-left: 40px;
  -webkit-box-shadow: 5px 9px 16px -1px #000000;
  box-shadow: 5px 9px 16px -1px #000000;
  position: absolute;
  top: -260px;
  z-index: 1000 !important;
  transform: rotate(-6deg);
}

.katalog-header {
  flex: 5;
  margin-left: 20px;
  text-align: center;
}

.katalog-header a {
  color: #fff;
  text-decoration: none;
}

.katalog-header h3 {
  font-size: 46px;
  font-weight: 400;
}

.katalog-header p {
  font-size: 26px;
  font-weight: 300;
}

.katalog-div {
  flex: 2.5;
}

.katalog-icon {
  font-size: 32px;
  margin: 0 6px;
}

@media only screen and (max-width: 480px) {
  .katalog {
    max-height: 32vh;
    max-width: 100%;
  }
  .katalog-image {
    width: 110px;
    top: -140px;
    margin-left: 2px;
  }

  .katalog-header {
    text-align: left;
  }

  .katalog-header h3 {
    font-size: 24px;
    margin-top: 4px;
  }

  .katalog-header p {
    font-size: 18px;
    text-align: left;
  }

  .katalog-icon {
    font-size: 21px;
  }

  .katalog-div {
    flex: 0.1;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .katalog {
    max-height: 32vh;
    max-width: 100%;
  }
  .katalog-image {
    width: 136px;
    top: -140px;
    margin-left: 16px;
  }

  .katalog-header {
    text-align: left;
  }

  .katalog-header h3 {
    font-size: 24px;
    margin-top: 4px;
  }

  .katalog-header p {
    font-size: 18px;
    text-align: left;
  }

  .katalog-icon {
    font-size: 21px;
  }

  .katalog-div {
    flex: 0.1;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .katalog {
    max-height: 32vh;
    max-width: 100%;
  }
  .katalog-image {
    width: 160px;
    top: -180px;
    margin-right: 26px;
  }

  .katalog-header {
    text-align: left;
  }

  .katalog-header h3 {
    font-size: 30px;
    margin-top: 4px;
  }

  .katalog-header p {
    font-size: 18px;
    text-align: left;
  }

  .katalog-icon {
    font-size: 21px;
  }

  .katalog-div {
    flex: 0.2;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .katalog {
    max-height: 36vh;
    max-width: 100%;
  }

  .katalog-image {
    width: 190px;
    top: -180px;
    margin-right: 26px;
  }

  .katalog-header h3 {
    font-size: 36px;
    margin-top: 4px;
  }

  .katalog-header p {
    font-size: 22px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .katalog-image {
    width: 180px;
    top: -180px;
    margin-right: 24px;
  }

  .katalog-header h3 {
    font-size: 32px;
    margin-top: 4px;
  }

  .katalog-header p {
    font-size: 20px;
  }
}
