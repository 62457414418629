.aktionen {
  height: auto;
  width: 100%;
}

.aktionen-wrap {
  position: relative;
  overflow: hidden;
}

.aktionen-top {
  height: 22vh !important;
  background-color: #395693;
  margin-top: 0;
  z-index: 12000;
}

.aktionen-side1 {
  width: 0;
  height: 0;
  border-top: 55vw solid #395693;
  border-right: 50vw solid transparent;
  position: relative;
  z-index: 12000;
  overflow: hidden;
}

.aktionen-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.aktionen-mid {
  width: 400px;
  position: relative;
  text-align: center;
  z-index: 12222;
}

.aktionen-side2 {
  width: 0;
  height: 0;
  border-top: 55vw solid #395693;
  border-left: 50vw solid transparent;
  position: relative;
  z-index: 1000;
}

.glass-img {
  width: 70vw;
  transform: rotate(-110deg);
  position: absolute;
  z-index: 200;
  left: -10vw;
  top: -12px;
}

.aktionen-bohrer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  z-index: 10000;
}

.aktionen-header {
  margin-bottom: 2rem;
  z-index: 1000;
}

.aktionen-header h3 {
  font-size: 68px;
  margin: 0;
  font-weight: 500;
}

.aktionen-header p {
  font-size: 26px;
}

.aktionen-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  height: 260px;
  width: 260px;
  background-color: #d4cb92;
  color: #fff;
  border-radius: 60px;
  text-align: center;
  z-index: 9999;
  text-decoration: none;
}

.aktionen-link:hover {
  cursor: pointer !important;
}

.aktionen-link h4 {
  font-size: 44px;
  font-weight: 400;
  margin-top: 36px;
}

.aktionen-link .aktionen-icon {
  font-size: 72px;
  margin-top: -48px;
}

@media only screen and (max-width: 480px) {
  .aktionen {
    height: 100%;
  }
  .aktionen-top {
    height: 8vh !important;
  }

  .aktionen-bohrer {
    margin-top: -140px;
  }

  .aktionen-bohr {
    margin-top: 340px;
  }

  .aktionen-header h3 {
    font-size: 30px;
  }

  .aktionen-header p {
    font-size: 20px;
  }

  .aktionen-link {
    height: 120px;
    width: 120px;
    border-radius: 20px;
  }

  .aktionen-link h4 {
    font-size: 20px;
    margin-top: 18px;
  }

  .aktionen-link .aktionen-icon {
    font-size: 36px;
  }

  .glass-img {
    z-index: 9999;
    top: -2px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .aktionen {
    height: 100%;
  }
  .aktionen-top {
    height: 9vh !important;
  }

  .aktionen-bohrer {
    margin-top: -240px;
  }

  .aktionen-bohr {
    margin-top: 80px;
  }

  .aktionen-header h3 {
    font-size: 30px;
  }

  .aktionen-header p {
    font-size: 20px;
  }

  .aktionen-link {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    margin-bottom: 19px;
  }

  .aktionen-link h4 {
    font-size: 18px;
    margin-top: 18px;
  }
  .aktionen-link .aktionen-icon {
    font-size: 32px;
  }

  .glass-img {
    z-index: 9999;
    top: -2px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .aktionen {
    height: 100%;
  }

  .aktionen-top {
    height: 13vh;
  }

  .aktionen-bohrer {
    margin-top: -340px;
  }

  .aktionen-bohr {
    margin-top: 80px;
  }

  .aktionen-header h3 {
    font-size: 52px;
  }

  .aktionen-header p {
    font-size: 22px;
  }

  .aktionen-link {
    height: 140px;
    width: 140px;
    border-radius: 20px;
    left: 200px;
    margin-bottom: 50px;
  }

  .aktionen-link h4 {
    font-size: 20px;
    margin-top: 20px;
  }
  .aktionen-link .aktionen-icon {
    font-size: 36px;
  }

  .glass-img {
    z-index: 9999;
    top: -2px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .aktionen {
    height: 100%;
  }

  .aktionen-top {
    height: 10vh !important;
  }

  .aktionen-bohrer {
    margin-top: -410px;
  }

  .aktionen-bohr {
    margin-top: 80px;
  }

  .aktionen-header h3 {
    font-size: 56px;
  }

  .aktionen-header p {
    font-size: 24px;
  }

  .aktionen-link {
    height: 140px;
    width: 140px;
    border-radius: 30px;
    left: 300px;
    margin-bottom: 120px;
  }

  .aktionen-link h4 {
    font-size: 20px;
    margin-top: 20px;
  }
  .aktionen-link .aktionen-icon {
    font-size: 36px;
  }

  .glass-img {
    z-index: 9999;
    top: -2px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1450px) {
  .aktionen {
    height: 100rem;
  }

  .aktionen-top {
    height: 16vh !important;
  }

  .aktionen-bohrer {
    margin-top: -35rem;
  }

  .aktionen-bohr {
    margin-top: 80px;
  }

  .aktionen-header h3 {
    font-size: 56px;
  }

  .aktionen-header p {
    font-size: 24px;
  }

  .aktionen-link {
    height: 200px;
    width: 220px;
    margin-bottom: 200px !important;
  }

  .aktionen-link h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1451px) {
  .aktionen {
    height: 100%;
  }

  .aktionen-bohrer {
    margin-top: -25%;
    overflow: hidden;
  }

  .aktionen-link {
    height: 240px;
    width: 240px;
  }
}

@media only screen and (min-width: 1951px) {
  .aktionen-link {
    height: 240px;
    width: 240px;
    margin-bottom: 220px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  .aktionen {
    height: auto;
  }

  .aktionen-top {
    height: 14vh;
  }

  .aktionen-bohrer {
    margin-top: -490px;
  }

  .aktionen-bohr {
    margin-top: 80px;
  }

  .aktionen-header h3 {
    font-size: 46px;
  }

  .aktionen-header p {
    font-size: 24px;
  }

  .aktionen-link {
    height: 200px;
    width: 220px;
  }

  .aktionen-link h4 {
    font-size: 30px;
  }

  .aktionen-link .aktionen-icon {
    font-size: 52px;
    margin-top: -40px;
  }
}
