.google {
  background-image: url(../../images/google_back.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  max-height: 50vh;
  max-width: 100%;
  overflow: hidden;
}

.google-over {
  background: rgb(215, 160, 127, 0.8);
  overflow: hidden;
  height: 100%;
  z-index: 4;
}

.google-header {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 1.6rem;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  margin-top: 3rem;
}

.google-header h3 {
  font-weight: 500;
}

.google-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.google-logo img {
  width: 400px;
}

@media only screen and (max-width: 480px) {
  .google-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .google-header h3 {
    font-size: 28px;
  }

  .google-logo img {
    margin-top: 2rem;
    width: 300px;
  }
}
