.chat-bubble-link {
  position: fixed;
  bottom: 10px; /* Adjust as needed */

  background-color: #007bff; /* Choose a background color */
  color: white; /* Icon color */
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 1000;
}

.chat-bubble-link:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

@media only screen and (max-width: 999px) {
  .chat-bubble-link {
    display: none;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .chat-bubble-link {
    bottom: 0px; /* Adjust as needed */
  }
}

@media only screen and (min-width: 1251px) {
  .chat-bubble-link {
    bottom: 10px; /* Adjust as needed */
  }
}
