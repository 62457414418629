.showcase {
  position: relative;
  height: 89vh;
  width: 100% !important;
  z-index: 1;
}

.showcase img {
  height: 100%;
  width: 100%;
}

.show {
  width: 100%;
  height: 100%;
}

.big-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 38vh !important;
}

.big-logo div {
  padding: 20px;
}

.big-logo div img {
  opacity: 1 !important;
}

@media only screen and (max-width: 480px) {
  .showcase {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .showcase {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .showcase {
    height: 86vh;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .showcase {
    display: block;
    height: 85vh;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .show {
    display: block !important;
  }
}
