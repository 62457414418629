.footer {
  height: auto;
  width: 100%;
  background-color: #395693;
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 60px 40px;
  max-width: 1660px;
  margin: auto;
}

.footer-kontakt {
  flex: 2.5;
}

.footer-kontakt h5,
.footer-links h5 {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

.footer-links h5 {
  margin-bottom: 20px;
}

.footer-links {
  flex: 2.5;
}

.footer-kontakt p,
.footer-links a,
.footer-kontakt a {
  font-size: 18px;
}

.footer-kontakt a {
  color: #fff;
  text-decoration: none;
}

.footer-kontakt .mt {
  margin-top: 34px;
}

.footer-links ul {
  padding-left: 0;
  margin-top: 0;
}

.footer-links ul li {
  padding-top: 14px;
  list-style-type: none;
}

.footer-links ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-link .mt {
  padding-top: 90px !important;
}

.stopa {
  border-top: 1px solid #fff;
  bottom: 0 !important;
}

.stopa-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1660px;
  margin: auto;
}

.stopa-wrapper .stopa-links {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
}

.stopa-links a {
  text-decoration: none;
  color: #9caac9;
  margin-right: 10px;
}

.stopa-links p {
  margin: 0;
  padding-right: 10px;
  color: #9caac9;
}

.stopa-copy p {
  margin-right: 30px;
  color: #9caac9;
}

.footer-newsletter {
  display: flex;
  flex: 5;
  flex-direction: column;
}

.footer-newsletter .footer-header-news {
  display: flex;
  flex-direction: row;
  flex: 7;
  justify-content: space-between;
  position: relative;
}
.footer-newsletter .footer-header-news h5 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
}

.footer-gutschein {
  background-color: #c79b81;
  width: 180px;
  height: 180px;
  border-radius: 40px;
  text-align: center;
  position: absolute;
  right: 0;
  top: -90px;
}

.footer-gutschein p {
  font-size: 38px;
  margin: 0;
}

.footer-gutschein .bigger {
  font-size: 50px;
  padding-top: 14px;
}

.footer-form-news form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.footer-form {
  padding: 14px;
  font-size: 15px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #fff;
  opacity: 0.5;
  outline: none;
  border: none;
}

.footer-gutschein:hover {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .footer {
    height: 100%;
  }
  .footer-wrapper {
    display: block;
  }

  .footer-kontakt h5,
  .footer-links h5 {
    font-size: 20px;
  }

  .footer-kontakt p,
  .footer-links a {
    font-size: 16px;
  }

  .footer-kontakt .mt {
    margin-top: 20px;
  }

  .footer-links h5 {
    margin-top: 40px;
    margin-bottom: 6px;
  }

  .footer-newsletter .footer-header-news h5 {
    font-size: 20px;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-newsletter .footer-header-news {
    flex-direction: column;
  }

  .footer-gutschein {
    position: relative;
    top: 0;
    width: 120px;
    height: 120px;
    margin: auto;
  }

  .footer-gutschein .bigger {
    font-size: 24px;
    margin-top: 8px;
  }

  .footer-gutschein p {
    font-size: 20px;
  }

  .stopa-wrapper {
    flex-direction: column;
  }

  .stopa-links {
    margin-top: 8px;
  }

  .stopa-copy p {
    margin-right: 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer {
    height: 100%;
  }
  .footer-wrapper {
    display: block;
  }

  .footer-kontakt h5,
  .footer-links h5 {
    font-size: 20px;
  }

  .footer-kontakt p,
  .footer-links a {
    font-size: 16px;
  }

  .footer-kontakt .mt {
    margin-top: 20px;
  }

  .footer-links h5 {
    margin-top: 40px;
    margin-bottom: 6px;
  }

  .footer-newsletter .footer-header-news h5 {
    font-size: 20px;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-newsletter .footer-header-news {
    flex-direction: column;
  }

  .footer-gutschein {
    position: relative;
    top: 0;
    width: 120px;
    height: 120px;
    margin: auto;
  }

  .footer-gutschein .bigger {
    font-size: 24px;
    margin-top: 8px;
  }

  .footer-gutschein p {
    font-size: 20px;
  }

  .stopa-wrapper {
    flex-direction: column;
  }

  .stopa-links {
    margin-top: 8px;
  }

  .stopa-copy p {
    margin-right: 0;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .footer {
    height: auto;
  }
  .footer-wrapper {
    display: flex;
    flex-direction: row;
    padding: 10px 25px;
    height: auto;
    margin: 20px 0;
  }

  .footer-kontakt {
    margin-right: 20px;
  }
  .footer-kontakt h5 {
    margin-top: 18px;
  }

  .footer-kontakt h5,
  .footer-links h5 {
    font-size: 20px;
  }

  .footer-kontakt p,
  .footer-links a {
    font-size: 16px;
  }

  .footer-kontakt .mt {
    margin-top: 20px;
  }

  .footer-links h5 {
    margin-top: 40px;
    margin-bottom: 6px;
  }

  .footer-newsletter .footer-header-news h5 {
    font-size: 18px;

    text-align: center;
  }

  .footer-newsletter .footer-header-news {
    flex-direction: row;
  }

  .footer-gutschein {
    position: relative;
    top: 0;
    width: 120px;
    height: 120px;
    margin: auto;
  }

  .footer-gutschein .bigger {
    font-size: 24px;
    margin-top: 8px;
  }

  .footer-gutschein p {
    font-size: 20px;
  }

  .stopa-wrapper {
    flex-direction: column;
  }

  .stopa-links {
    margin-top: 8px;
    font-size: 16px !important;
  }

  .stopa-copy p {
    margin-right: 0;
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .footer-kontakt h5,
  .footer-links h5 {
    font-size: 22px;
  }

  .footer-links h5 {
    margin-top: 18px;
  }

  .footer-kontakt p,
  .footer-links a {
    font-size: 18px;
  }

  .footer-newsletter .footer-header-news h5 {
    font-size: 20px;
    text-align: left;
    margin-top: 30px;
  }

  .footer-gutschein {
    position: relative;
    top: 0;
    width: 140px;
    height: 140px;
    padding: 0 20px;
    margin-left: 10px;
  }

  .footer-gutschein .bigger {
    font-size: 34px;
    margin-top: 8px;
  }

  .footer-gutschein p {
    font-size: 24px;
  }

  .show {
    display: none;
  }

  .stopa-links {
    margin-top: 6px;
    font-size: 16px !important;
  }

  .stopa-copy p {
    margin-right: 0;
    font-size: 16px !important;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .footer-kontakt h5,
  .footer-links h5 {
    font-size: 20px;
  }

  .footer-links h5 {
    margin-top: 16px;
  }

  .footer-kontakt p,
  .footer-links a {
    font-size: 16px;
  }

  .footer-newsletter .footer-header-news h5 {
    font-size: 18px;
    text-align: left;
    margin-top: 32px;
  }

  .footer-gutschein {
    position: relative;
    top: 0;
    width: 120px;
    height: 120px;
    padding: 0 20px;
    margin-left: 10px;
  }

  .footer-gutschein .bigger {
    font-size: 26px;
    margin-top: 8px;
  }

  .footer-gutschein p {
    font-size: 20px;
  }

  .show {
    display: none;
  }

  .stopa-links {
    margin-top: 6px;
    font-size: 16px !important;
  }

  .stopa-copy p {
    margin-right: 0;
    font-size: 16px !important;
  }
}
