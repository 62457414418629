.team-header {
  text-align: center;
  padding-top: 60px;
  max-width: 1100px;
  margin: auto;
}

.team-header h2 {
  font-size: 70px;
  font-weight: 300;
  margin-bottom: 0;
}

.team-header h3 {
  font-size: 30px;
  font-weight: 400;
  margin-top: 16px;
}

.team-header .blue {
  font-weight: 500;
}

.team-header p {
  font-size: 24px;
  margin-bottom: 20px;
  color: #9e9e9e;
  font-weight: 300;
  margin-top: 80px;
  line-height: 1.7;
  padding: 0 40px;
}

.team-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  position: relative;
  max-width: 1800px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 140px;
}

.team-images-first,
.team-images-second {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.team-image {
  border-radius: 70px;
  height: 320px;
  width: 360px;
  transition: all 0.3s ease-out;
  margin-right: 20px;
}

.team-image:hover {
  transform: scale(1.3);
  cursor: pointer;
  z-index: 999;
}

.team-image .team-img {
  border-radius: 70px;
  height: 320px;
  width: 360px;
  background: rgb(120, 148, 147, 0.8);
  transition: 0.2s all ease-out;
}

.dirk {
  background-image: url(../../images/tempelhagen.PNG);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma1 {
  background-image: url(../../images/ma4.PNG);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma2 {
  background-image: url(../../images/Ma1.PNG);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma3 {
  background-image: url(../../images/Ma3.PNG);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma4 {
  background-image: url(../../images/MaB.PNG);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma5 {
  background-image: url(../../images/Ma5.PNG);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma6 {
  background-image: url(../../images/Ma6.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.ma7 {
  background-image: url(../../images/Ma7.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  display: block;
}

.dirk .image-over,
.ma1 .image-over,
.ma2 .image-over,
.ma3 .image-over,
.ma4 .image-over,
.ma5 .image-over,
.ma6 .image-over,
.ma7 .image-over {
  background: rgb(120, 148, 147, 0.8);
  overflow: hidden;
  height: 100%;
  border-radius: 70px;
  height: 320px;
  width: 360px;
  z-index: 2;
}

.team-image-wrap {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-top: 40px;
}

.team-image-wrap h4 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}

.team-image-wrap p {
  font-size: 20px;
  margin: 4px 0;
}

.team-image-wrap a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}
.swiper {
  height: 500px !important;
}
.swiper-wrapper {
  padding-top: 80px !important;
}

@media only screen and (max-width: 480px) {
  .team-header {
    margin-top: 40px;
  }

  .team-header h2 {
    font-size: 34px;
  }

  .team-header h3 {
    font-size: 20px;
  }

  .team-header p {
    font-size: 18px;
  }

  .team-image {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    margin-right: 6px;
  }

  .ma1,
  .ma2,
  .ma3,
  .ma4,
  .ma5,
  .ma6,
  .ma7,
  .dirk,
  .image-over {
    height: 120px;
    width: 120px;
    border-radius: 20px;
  }

  .team-image-wrap {
    padding-top: 2px;
  }

  .team-image-wrap h4 {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 2px;
  }

  .team-image-wrap p {
    font-size: 10px;
  }

  .team-image-wrap a {
    font-size: 10px;
  }

  .dirk .image-over,
  .ma1 .image-over,
  .ma2 .image-over,
  .ma3 .image-over,
  .ma4 .image-over,
  .ma5 .image-over,
  .ma6 .image-over,
  .ma7 .image-over {
    height: 120px;
    width: 120px;
    border-radius: 20px;
  }

  .team-images-second {
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .team-header {
    margin-top: 40px;
  }

  .team-header h2 {
    font-size: 34px;
  }

  .team-header h3 {
    font-size: 20px;
  }

  .team-header p {
    font-size: 18px;
  }

  .team-image {
    height: 140px;
    width: 140px;
    border-radius: 20px;
    margin-right: 6px;
  }

  .ma1,
  .ma2,
  .ma3,
  .ma4,
  .ma5,
  .ma6,
  .ma7,
  .dirk,
  .image-over {
    height: 140px;
    width: 140px;
    border-radius: 20px;
  }

  .team-image-wrap {
    padding-top: 2px;
  }

  .team-image-wrap h4 {
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 2px;
  }

  .team-image-wrap p {
    font-size: 10px;
  }

  .team-image-wrap a {
    font-size: 10px;
  }

  .dirk .image-over,
  .ma1 .image-over,
  .ma2 .image-over,
  .ma3 .image-over,
  .ma4 .image-over,
  .ma5 .image-over,
  .ma6 .image-over,
  .ma7 .image-over {
    height: 140px;
    width: 140px;
    border-radius: 20px;
  }

  .team-images-second {
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .team-header {
    margin-top: 40px;
  }

  .team-header h2 {
    font-size: 52px;
  }

  .team-header h3 {
    font-size: 26px;
  }

  .team-header p {
    font-size: 20px;
  }

  .team-image {
    height: 180px;
    width: 180px;
    border-radius: 20px;
    margin-right: 20px;
  }

  .ma1,
  .ma2,
  .ma3,
  .ma4,
  .ma5,
  .ma6,
  .ma7,
  .dirk,
  .image-over {
    height: 180px;
    width: 180px;
    border-radius: 20px;
  }

  .team-image-wrap {
    padding-top: 2px;
  }

  .team-image-wrap h4 {
    font-size: 18px;
    margin-top: 20px;
  }

  .team-image-wrap p {
    font-size: 14px;
  }

  .team-image-wrap a {
    font-size: 14px;
  }

  .dirk .image-over,
  .ma1 .image-over,
  .ma2 .image-over,
  .ma3 .image-over,
  .ma4 .image-over,
  .ma5 .image-over,
  .ma6 .image-over,
  .ma7 .image-over {
    height: 180px;
    width: 180px;
    border-radius: 20px;
  }

  .team-images-second {
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .team-header h2 {
    font-size: 58px;
  }

  .team-header h3 {
    font-size: 26px;
  }

  .team-header p {
    font-size: 22px;
  }

  .team-image {
    height: 250px;
    width: 250px;
    border-radius: 40px;
    margin-right: 20px;
  }

  .ma1,
  .ma2,
  .ma3,
  .ma4,
  .ma5,
  .ma6,
  .ma7,
  .dirk,
  .image-over {
    height: 250px;
    width: 250px;
    border-radius: 40px;
  }

  .dirk .image-over,
  .ma1 .image-over,
  .ma2 .image-over,
  .ma3 .image-over,
  .ma4 .image-over,
  .ma5 .image-over,
  .ma6 .image-over,
  .ma7 .image-over {
    height: 250px;
    width: 250px;
    border-radius: 40px;
  }

  .team-image-wrap h4 {
    font-size: 22px;
    margin-top: 20px;
  }

  .team-image-wrap p {
    font-size: 16px;
  }

  .team-image-wrap a {
    font-size: 16px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  .team-header h2 {
    font-size: 48px;
  }

  .team-header h3 {
    font-size: 22px;
  }

  .team-header p {
    font-size: 18px;
  }

  .team-image-wrap h4 {
    font-size: 20px;
    margin-top: 20px;
  }

  .team-image-wrap p {
    font-size: 15px;
  }

  .team-image-wrap a {
    font-size: 15px;
  }

  .ma1,
  .ma2,
  .ma3,
  .ma4,
  .ma5,
  .ma6,
  .ma7 .dirk,
  .image-over {
    height: 280px;
    width: 280px;
    border-radius: 40px;
  }

  .dirk .image-over,
  .ma1 .image-over,
  .ma2 .image-over,
  .ma3 .image-over,
  .ma4 .image-over,
  .ma5 .image-over,
  .ma6 .image-over,
  .ma7 .image-over {
    height: 280px;
    width: 280px;
    border-radius: 40px;
  }
}
