.nav {
  background-color: #395693;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999999;
}

.nav .s-logo {
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 8px;
}

.nav .s-logo:hover {
  cursor: pointer;
}

.nav .res-logo {
  display: none;
}

.nav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-right: 40px;
  padding-right: 20px;
}

.nav ul li {
  margin-right: 36px;
  margin-top: 60px;
  text-transform: uppercase;
}

.nav ul li a {
  border-bottom: 2px solid #395693;
  transition: all 0.3s ease-in-out;
}

.nav ul li a:hover {
  border-bottom: 2px solid #fff;
}

.menu {
  display: flex;
  flex-direction: row;
}

.menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s ease-in-out;
}

.menu-item a {
  color: #fff;
  text-decoration: none;
}

.menu-icon {
  padding-top: 5px;
  margin-left: 3px;
}

.hamburger {
  width: 32px;
  height: 25px !important;

  flex-direction: column;
  justify-content: space-between !important;
  cursor: pointer;
  display: none;
}

.line1,
.line2,
.line3 {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transform-origin: left;
  transition: all 1s ease;
}

@media only screen and (max-width: 480px) {
  .nav .s-logo {
    display: none;
  }

  .nav .res-logo {
    display: block;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 8px;
  }

  .nav {
    height: 80px;
  }

  .dt-logo {
    height: 50px;
  }

  .menu-list {
    display: none;
  }

  .hamburger {
    display: flex;
    margin-right: 20px;
    margin-top: 27px;
  }

  .hamburger.active .line1 {
    transform: rotate(45deg);
  }

  .hamburger.active .line2 {
    opacity: 0;
  }

  .hamburger.active .line3 {
    transform: rotate(-45deg);
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .nav {
    width: 100%;
    height: 80px;
  }
  .nav .s-logo {
    display: none;
  }

  .nav .res-logo {
    display: block;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 8px;
  }
  .dt-logo {
    height: 50px;
  }

  .menu-list {
    display: none;
  }

  .hamburger {
    display: flex;
    margin-right: 20px;
    margin-top: 27px;
  }

  .hamburger.active .line1 {
    transform: rotate(45deg);
  }

  .hamburger.active .line2 {
    opacity: 0;
  }

  .hamburger.active .line3 {
    transform: rotate(-45deg);
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .nav {
    width: 100%;
    height: 108px;
  }
}
