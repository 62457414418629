.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 12rem;
  max-width: 1200px;
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery h2 {
  margin-top: 10rem;
  font-size: 70px;
  font-weight: 500;
  color: #395693;
  text-transform: uppercase;
}

.galleryWrap .single {
  width: 360px;
  height: 330px;
  cursor: pointer;
  border-radius: 70px;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
  border: 9px solid #fff;
  position: relative;
}

.galleryWrap .single img {
  max-width: 100%;
  height: 330px;
  object-fit: cover;
  margin: 0;
}

.galleryWrap .single:hover {
  transform: scale(1.075);
  border: 9px solid #395693;
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;

  z-index: 555555555;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 240px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.imageWrap {
  position: relative;
}

.textWrap {
  position: absolute;
  top: 13rem;
  left: 2rem;
  color: #fff;
  transition: 0.3s all ease-out;
}

.textWrap h4 {
  font-size: 1.4rem;
  margin: 0;
}

.textWrap p {
  margin: 0;
  padding-top: 1.25rem;
}

@media only screen and (max-width: 480px) {
  .gallery {
    flex-direction: column;
  }

  .gallery h2 {
    text-align: center;
    font-size: 34px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .gallery h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .gallery h2 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .gallery h2 {
    font-size: 58px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  .gallery h2 {
    font-size: 48px;
  }
}
