.submit-newsletter {
  padding: 8px;
  margin-top: 8px;
  background-color: #c79b81;
  font-size: 16px;
  border-radius: 10px;
  transition: 0.3s all ease-out;
  border: none;
  color: #fff;
}

.submit-newsletter:hover {
  cursor: pointer;
}

.mc__alert {
  margin-top: 12px;
  text-align: center;
}
