.dt-shop {
  background-image: url(../../images/Pastelless.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  max-height: 50vh;
  max-width: 100%;
  overflow: hidden;
  margin-top: 120px;
  position: relative;
  overflow: visible;
  color: #fff !important;
}

.dt-shop a {
  color: #fff;
  text-decoration: none;
}

.dt-shop-over {
  background: rgb(212, 203, 146, 0.8);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.dt-shop-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dt-shop-icon {
  font-size: 148px;
  margin-top: 20px;
}

.dt-shop-header {
  text-align: center;
}

.dt-shop-header h3 {
  font-size: 66px;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 20px;
}

.dt-shop-header h4 {
  font-size: 22px;
  font-weight: 400;
  margin-top: 20px;
}

@media only screen and (max-width: 480px) {
  .dt-shop {
    max-height: 50vh;
  }
  .dt-shop-icon {
    font-size: 80px;
    margin-top: 16px;
  }

  .dt-shop-header h3 {
    font-size: 34px;
  }

  .dt-shop-header h4 {
    font-size: 20px;
    padding: 0 20px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .dt-shop {
    max-height: 50vh;
  }
  .dt-shop-icon {
    font-size: 80px;
    margin-top: 20px;
  }

  .dt-shop-header h3 {
    font-size: 34px;
  }

  .dt-shop-header h4 {
    font-size: 20px;
    padding: 0 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .dt-shop {
    max-height: 50vh;
  }
  .dt-shop {
    margin-top: 40px;
  }
  .dt-shop-icon {
    font-size: 100px;
    margin-top: 24px;
  }

  .dt-shop-header h3 {
    font-size: 52px;
  }

  .dt-shop-header h4 {
    font-size: 20px;
    padding: 0 40px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .dt-shop {
    max-height: 50vh;
  }
  .dt-shop {
    margin-top: 20px;
  }
  .dt-shop-icon {
    font-size: 100px;
    margin-top: 20px;
  }

  .dt-shop-header h3 {
    font-size: 50px;
  }

  .dt-shop-header h4 {
    font-size: 24px;
    padding: 0 40px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  .dt-shop {
    max-height: 50vh;
  }
  .dt-shop {
    margin-top: 10px;
  }
  .dt-shop-icon {
    font-size: 80px;
    margin-top: 20px;
  }

  .dt-shop-header h3 {
    font-size: 42px;
  }

  .dt-shop-header h4 {
    font-size: 22px;
    padding: 0 20px;
  }
}
