.chat-popup-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-popup {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  width: 300px;
  height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
}

.chat-popup-container.open .chat-popup {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.chat-toggle-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
}

.chat-toggle-btn:hover {
  background-color: #0056b3;
}
