.aktionenBild {
  height: auto;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
}

.aktionenBild .image {
  z-index: -10;
  height: 100%;
  width: 100%;
}

.aktionenBild .image img {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 1951px) {
  .aktionenBild {
    margin-top: -940px;
  }
}

@media only screen and (min-width: 1451px) {
  .aktionenBild {
    margin-top: -420px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1450px) {
  .aktionenBild {
    margin-top: -850px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .aktionenBild {
    margin-top: -240px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .aktionenBild {
    margin-top: -80px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .aktionenBild {
    margin-top: -140px;
  }
}
