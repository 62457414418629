.datenschutz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1099px;
  margin: auto;
  padding-bottom: 100px;
  margin-top: 80px;
}

.datenschutz h2 {
  font-size: 66px;
  font-weight: 400;
}

.datenschutz p {
  font-size: 20px;
  line-height: 1.7;
  color: #9e9e9e;
}

.datenschutz-text {
  font-size: 20px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 480px) {
  .datenschutz {
    width: auto;
    margin: 0 20px;
  }

  .datenschutz h2 {
    font-size: 30px;
    font-weight: 400;
  }

  .datenschutz p {
    font-size: 16px;
  }

  .datenschutz-text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .datenschutz {
    width: auto;
    margin: 0 30px;
  }

  .datenschutz h2 {
    font-size: 34px;
    font-weight: 400;
  }

  .datenschutz p {
    font-size: 16px;
  }

  .datenschutz-text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .datenschutz {
    width: auto;
    margin: 0 40px;
  }

  .datenschutz h2 {
    font-size: 44px;
    font-weight: 400;
  }

  .datenschutz p {
    font-size: 18px;
  }

  .datenschutz-text {
    font-size: 18px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .datenschutz {
    width: auto;
    margin: 0 50px;
  }

  .datenschutz h2 {
    font-size: 56px;
    font-weight: 400;
  }

  .datenschutz p {
    font-size: 19px;
  }

  .datenschutz-text {
    font-size: 19px;
  }
}
