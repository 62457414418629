.kontakt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  height: 100%;
}

.kontakt-header {
  text-align: center;
}

.kontakt-header h2 {
  font-size: 70px;
  font-weight: 500;
  margin-bottom: 20px;
}

.kontakt-header .kontakt-text {
  font-size: 26px;
}

.kontakt-data {
  margin-top: 80px;
}
.kontakt-data p {
  font-size: 24px;
  color: #9e9e9e;
}

.kontakt-form {
  margin-top: 120px;
  width: 60%;
}

.kontakt-form form {
  display: flex;
  flex-direction: column;
}

.kontakt-form form input {
  border: 0;
  border-bottom: 1px solid #000;
  margin-bottom: 16px;
  padding: 18px 0;
  text-align: center;
  font-size: 18px;
  outline: none;
}

.kontakt-form form textarea {
  margin-top: 40px;
  height: 300px;
  border: 0;
  border: 1px solid #000;
  outline: none;
  text-align: center;
  font-size: 18px;
  font-family: "Ubuntu", sans-serif;
}

.kontakt-form form .btn-kontakt {
  padding: 16px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #395693;
  color: #fff;
  font-size: 19px;
  border-radius: 8px;
}

.kontakt-form form .btn-kontakt:hover {
  cursor: pointer;
  background-color: #4262a5;
}

.kontakt-success {
  font-size: 18px;
  color: green;
  padding: 6px;
  border: 1px solid #000;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .kontakt-header h2 {
    font-size: 34px;
    padding: 0 10px;
  }
  .kontakt-header .kontakt-text {
    font-size: 20px;
    padding: 0 10px;
  }

  .kontakt-data p {
    font-size: 18px;
  }

  .kontakt-form {
    margin-top: 60px;
    width: 70%;
  }

  .kontakt-form form input {
    font-size: 15px;
  }

  .kontakt-form form textarea {
    font-size: 14px;
  }

  .kontakt-form form .btn-kontakt {
    font-size: 16px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .kontakt-header h2 {
    font-size: 34px;
    padding: 0 10px;
  }
  .kontakt-header .kontakt-text {
    font-size: 20px;
    padding: 0 10px;
  }

  .kontakt-data p {
    font-size: 18px;
  }

  .kontakt-form {
    margin-top: 60px;
    width: 70%;
  }

  .kontakt-form form input {
    font-size: 15px;
  }

  .kontakt-form form textarea {
    font-size: 14px;
  }

  .kontakt-form form .btn-kontakt {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .kontakt-header h2 {
    font-size: 52px;
  }
  .kontakt-header .kontakt-text {
    font-size: 20px;
    padding: 0 10px;
  }

  .kontakt-data p {
    font-size: 18px;
  }

  .kontakt-form {
    margin-top: 50px;
    width: 70%;
  }

  .kontakt-form form input {
    font-size: 16px;
  }

  .kontakt-form form textarea {
    font-size: 15px;
  }

  .kontakt-form form .btn-kontakt {
    font-size: 16px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .kontakt-header h2 {
    font-size: 58px;
  }

  .kontakt-header .kontakt-text {
    font-size: 24px;
    padding: 0 10px;
  }

  .kontakt-data p {
    font-size: 20px;
  }

  .kontakt-form {
    margin-top: 68px;
    width: 70%;
  }

  .kontakt-form form input {
    font-size: 18px;
  }

  .kontakt-form form textarea {
    font-size: 16px;
  }

  .kontakt-form form .btn-kontakt {
    font-size: 18px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  .kontakt-header h2 {
    font-size: 48px;
  }

  .kontakt-header .kontakt-text {
    font-size: 22px;
    padding: 0 10px;
  }

  .kontakt-data p {
    font-size: 18px;
  }

  .kontakt-form {
    margin-top: 68px;
    width: 60%;
  }

  .kontakt-form form input {
    font-size: 16px;
  }

  .kontakt-form form textarea {
    font-size: 16px;
  }

  .kontakt-form form .btn-kontakt {
    font-size: 16px;
  }
}
