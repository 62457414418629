.impressum {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 1099px;
  margin-top: 80px;
}

.impressum-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 80px;
}
.impressum-header h2,
.impressum-text h2 {
  font-size: 66px;
  font-weight: 400;
}

.impressum-text h2 {
  margin-top: 120px;
  margin-bottom: 8px;
}

.impressum-text p {
  font-size: 20px;
  color: #9e9e9e;
  text-align: left;
  line-height: 1.57;
}

.haftung {
  text-align: center;
  width: 100%;
}

.impressum-info p {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #9e9e9e;
}

@media only screen and (max-width: 480px) {
  .impressum {
    width: auto;
    margin: 0 20px;
  }

  .impressum-header h2,
  .impressum-text h2 {
    font-size: 38px;
    font-weight: 400;
  }

  .impressum-info p,
  .impressum-text p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .impressum {
    width: auto;
    margin: 0 30px;
  }

  .impressum-header h2,
  .impressum-text h2 {
    font-size: 40px;
    font-weight: 400;
  }

  .impressum-info p,
  .impressum-text p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .impressum {
    width: auto;
    margin: 0 40px;
  }

  .impressum-header h2,
  .impressum-text h2 {
    font-size: 46px;
    font-weight: 400;
  }

  .impressum-info p,
  .impressum-text p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .impressum {
    width: auto;
    margin: 0 50px;
  }

  .impressum-header h2,
  .impressum-text h2 {
    font-size: 56px;
    font-weight: 400;
  }

  .impressum-info p,
  .impressum-text p {
    font-size: 19px;
  }
}
