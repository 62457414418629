.newsletter {
  background-image: url(../../images/tempel.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  max-height: 50vh;
  max-width: 100%;
  overflow: hidden;

  margin-bottom: 80px;
  position: relative;
  overflow: visible;
}

.newsletter-over {
  background: rgb(215, 160, 127, 0.8);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.newsletter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.newsletter-icon {
  color: #fff;
  font-size: 148px;
  margin-top: 20px;
}

.newsletter-header h3 {
  font-size: 64px;
  text-align: center;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
}

.newsletter-header h4 {
  font-size: 32px;
  margin-top: 10px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .newsletter-icon {
    font-size: 80px;
  }
  .newsletter-header h3 {
    font-size: 34px;
  }

  .newsletter-header h4 {
    font-size: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .newsletter-icon {
    font-size: 80px;
  }
  .newsletter-header h3 {
    font-size: 34px;
  }

  .newsletter-header h4 {
    font-size: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .newsletter-icon {
    font-size: 100px;
  }
  .newsletter-header h3 {
    font-size: 46px;
  }

  .newsletter-header h4 {
    font-size: 22px;
    text-align: center;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .newsletter {
    margin-bottom: 20px;
  }
  .newsletter-icon {
    font-size: 100px;
  }
  .newsletter-header h3 {
    font-size: 50px;
  }

  .newsletter-header h4 {
    font-size: 24px;
    text-align: center;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .newsletter {
    margin-bottom: 20px;
  }
  .newsletter-icon {
    font-size: 80px;
  }
  .newsletter-header h3 {
    font-size: 50px;
  }

  .newsletter-header h4 {
    font-size: 22px;
    text-align: center;
  }
}
