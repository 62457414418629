.sidenav {
  width: 180px;
  height: 100vh;
  background-color: #395693;
  position: fixed;
  top: 0;
  right: -180px;
  z-index: 99999;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  border-left: 1px solid #fff;
}

.sidenav.active {
  right: 0;
}

.sidenav ul {
  list-style: none;
  padding-left: 10px;
}

.sidenav-item {
  margin-bottom: 16px;
  font-size: 18px;
}

.sidenav-item a {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .sidenav {
    display: flex;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .sidenav {
    display: flex;
  }
}
