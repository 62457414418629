.blue {
  color: #395693;
}

.bg-blue {
  background-color: #395693;
}

.light-blue {
  color: #446cc2;
}

.prophy {
  color: #789493;
}

.bg-prophy {
  background-color: #789493;
}

.bohr {
  color: #d4cb93;
}

.bg-bohr {
  background-color: #d4cb93;
}

.poli {
  color: #d7a080;
}

.bg-poli {
  background-color: #d7a080;
}

.inst {
  color: #b1a8ba;
}

.bg-inst {
  background-color: #b1a8ba;
}

.endo {
  color: #9a8e81;
}

.bg-endo {
  background-color: #9a8e81;
}
