#info {
  background-image: url(../../images/team1.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.info-row {
  background: rgb(120, 148, 147, 0.8);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.info-header {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.info-header h2 {
  font-size: 52px;
  font-weight: 400;
  margin-bottom: 10px;
}

.info-header p {
  font-size: 32px;
  color: #fff;
}

.info-stats {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  margin-top: 80px;
  margin-bottom: 80px;
}

.info-stats h3 {
  font-size: 62px;
  font-weight: 400;
}

.stat h3 {
  font-size: 52px;
  text-align: center;
  font-weight: 400 !important;
  margin-bottom: 0;
}

.stat:last-child {
  padding-bottom: 40px;
}

.stat:last-child h3 {
  margin-top: 60px !important;
}

.text-info {
  width: 360px;
  margin: 0 auto;
  font-size: 26px;
  color: #fff;
  text-align: center;
  line-height: 1.7;
  font-weight: 300;
}

@media only screen and (max-width: 480px) {
  #info {
    height: 100% !important;
    max-width: 100%;
    background-position: center;
  }

  .info-header {
    padding: 8px 20px;
  }

  .info-header h2 {
    font-size: 28px;
    text-align: center;
  }

  .info-header p {
    font-size: 22px;
    text-align: center;
  }

  .info-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 38px;
  }

  .stat h3 {
    font-size: 30px;
  }

  .text-info p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  #info {
    height: 100% !important;
    max-width: 100%;
  }

  .info-header {
    padding: 8px 20px;
  }

  .info-header h2 {
    font-size: 28px;
    text-align: center;
  }

  .info-header p {
    font-size: 22px;
    text-align: center;
  }

  .info-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 38px;
  }

  .stat h3 {
    font-size: 30px;
  }

  .text-info p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  #info {
    height: 100% !important;
    max-width: 100%;
  }
  .info-stats {
    flex-direction: column;
  }

  .info-header h2 {
    text-align: center;
  }

  .info-header p {
    font-size: 26px;
    text-align: center;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  #info {
    max-width: 100%;
  }
  .info-header {
    text-align: center;
  }

  .info-header h2 {
    font-size: 50px;
    padding: 16px;
  }

  .info-header p {
    font-size: 28px;
  }

  .stat h3 {
    font-size: 40px;
  }

  .text-info {
    font-size: 22px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .info-header h2 {
    font-size: 46px;
    padding: 18px;
  }

  .info-header p {
    font-size: 24px;
  }

  .stat h3 {
    font-size: 32px;
  }

  .text-info {
    font-size: 20px;
  }
}
