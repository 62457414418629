.handy {
  background-image: url(../../images/full-team.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover !important;
  max-height: 50vh;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 80px;
  z-index: 1000;
  color: #fff;
  width: 100%;
  margin-top: -5px;
}

.handy-over {
  background: rgb(177, 167, 186, 0.8);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.handy-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.handy-icon {
  font-size: 148px;
  margin-top: 40px;
}

.handy-header {
  text-align: center;
}

.handy-header a {
  font-size: 66px;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 20px;
  text-decoration: none;
  color: #fff;
}

.handy-header h4 {
  font-size: 22px;
  font-weight: 400;
  margin-top: 20px;
}

@media only screen and (max-width: 480px) {
  .handy {
    max-height: 50vh;
  }
  .handy-icon {
    font-size: 80px;
  }

  .handy-header a {
    font-size: 34px;
  }

  .handy-header h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .handy {
    max-height: 50vh;
  }
  .handy-icon {
    font-size: 80px;
  }

  .handy-header a {
    font-size: 34px;
  }

  .handy-header h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .handy {
    max-height: 50vh;
  }
  .handy-icon {
    font-size: 100px;
  }

  .handy-header a {
    font-size: 52px;
  }

  .handy-header h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .handy {
    margin-bottom: 20px;
    max-height: 50vh;
  }
  .handy-icon {
    font-size: 100px;
    margin-top: 30px;
  }

  .handy-header a {
    font-size: 56px;
  }

  .handy-header h4 {
    font-size: 24px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  .handy-icon {
    font-size: 100px;
    margin-top: 30px;
  }
  .handy-header a {
    font-size: 46px;
  }

  .handy-header h4 {
    font-size: 22px;
  }
}
