.produkte-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.produkte-header h2 {
  font-size: 68px;
  font-weight: 500;
  margin-bottom: 0;
}

.produkte-header h3 {
  font-size: 26px;
  font-weight: 400;
}

.produkte-header p {
  max-width: 1090px;
  font-size: 24px;
  line-height: 1.7;
  text-align: center;
  color: #9e9e9e;
  font-weight: 300;
  margin-top: 48px;
}

.produkte-header span {
  font-weight: 500;
}

.produkte-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}

.produkte-icon {
  display: flex;
  flex-direction: row;
  transition: all 0.4s ease-out;
}

.icon {
  margin-right: 20px;
  transition: transform 0.35s;
  position: relative;
  margin-bottom: 30px;
}

.icon:hover {
  transform: scale(1.4);
  cursor: pointer;
  z-index: 11111;
}

.icon-prophy {
  background-image: url(../../images/Icon_Prophylaxe.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
  width: 290px;
}

.icon-bohrer {
  background-image: url(../../images/Icon_Bohrer.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
  width: 290px;
}

.icon-poli {
  background-image: url(../../images/Icon_Polierer.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
  width: 290px;
}

.icon-ins {
  background-image: url(../../images/Icon_Instrumente.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
  width: 290px;
}

.icon-stif {
  background-image: url(../../images/Icon_Stifte.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
  width: 290px;
}

.icon-endo {
  background-image: url(../../images/Icon_Endoinstrumente.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
  width: 290px;
}

.icon-front-pro {
  background-color: #7a9090;
  height: 290px;
  width: 290px;
  border-radius: 50px;
  z-index: 1111111;
}

.icon-front-boh {
  background-color: #d4cb92;
  height: 290px;
  width: 290px;
  border-radius: 50px;
  z-index: 1111111;
}

.icon-front-pol {
  background-color: #d7a07f;
  height: 290px;
  width: 290px;
  border-radius: 50px;
  z-index: 1111111;
}

.icon-front-ins {
  background-color: #b1a7ba;
  height: 290px;
  width: 290px;
  border-radius: 50px;
  z-index: 1111111;
}

.icon-front-stif {
  background-color: #c3c4c4;
  height: 290px;
  width: 290px;
  border-radius: 50px;
  z-index: 1111111;
}

.icon-front-endo {
  background-color: #9a8e81;
  height: 290px;
  width: 290px;
  border-radius: 50px;
  z-index: 1111111;
}

.icon-front-text {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-front-text h4 {
  font-size: 30px;
  margin-top: 60px;
  margin-bottom: 0;
}

.icon-front-text ul {
  font-size: 20px;
}

.icon-front-text ul li {
  padding: 3px 0;
}

@media only screen and (max-width: 480px) {
  .produkte {
    padding: 0 20px;
  }

  .produkte-header h2 {
    font-size: 34px;
    text-align: center;
  }

  .produkte-header h3 {
    font-size: 22px;
    text-align: center;
  }

  .produkte-header p {
    font-size: 18px;
    text-align: left;
  }

  .produkte-icons {
    margin-bottom: 80px;
  }

  .icon {
    margin-right: 6px;
  }

  .icon:hover {
    transform: scale(1.1);
  }

  .icon-bohrer,
  .icon-prophy,
  .icon-poli,
  .icon-endo,
  .icon-ins,
  .icon-stif {
    height: 120px;
    width: 120px;
    overflow: visible;
  }

  .icon-front-boh,
  .icon-front-pro,
  .icon-front-pol,
  .icon-front-endo,
  .icon-front-ins,
  .icon-front-stif {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    overflow: visible;
  }

  .icon-front-text h4 {
    font-size: 18px;
    margin-top: 16px;
  }

  .icon-front-text ul {
    font-size: 12px;
    list-style-type: none;
    padding-left: 0px;
    text-align: center;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .produkte {
    padding: 0 20px;
  }

  .produkte-header h2 {
    font-size: 34px;
    text-align: center;
  }

  .produkte-header h3 {
    font-size: 22px;
    text-align: center;
  }

  .produkte-header p {
    font-size: 18px;
    text-align: left;
  }

  .produkte-icons {
    margin-bottom: 80px;
  }

  .icon {
    margin-right: 6px;
  }

  .icon:hover {
    transform: scale(1.3);
  }

  .icon-wrap {
    height: 100%;
    overflow: visible;
  }

  .icon-bohrer,
  .icon-prophy,
  .icon-poli,
  .icon-endo,
  .icon-ins,
  .icon-stif {
    height: 120px;
    width: 120px;
  }

  .icon-front-boh,
  .icon-front-pro,
  .icon-front-pol,
  .icon-front-endo,
  .icon-front-ins,
  .icon-front-stif {
    height: 120px;
    width: 120px;
    border-radius: 20px;
  }

  .icon-front-text h4 {
    font-size: 16px;
    margin-top: 16px;
  }

  .icon-front-text ul {
    font-size: 12px;
    list-style-type: none;
    padding-left: 0px;
    text-align: center;
  }

  .icon-front-text ul {
    margin-top: 4px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .produkte-header h2 {
    font-size: 52px;
    text-align: center;
  }

  .produkte-header h3 {
    font-size: 26px;
    text-align: center;
  }

  .produkte-header p {
    font-size: 20px;
  }

  .icon-bohrer,
  .icon-prophy,
  .icon-poli,
  .icon-endo,
  .icon-ins,
  .icon-stif {
    height: 180px;
    width: 180px;
  }

  .icon-front-boh,
  .icon-front-pro,
  .icon-front-pol,
  .icon-front-endo,
  .icon-front-ins,
  .icon-front-stif {
    height: 180px;
    width: 180px;
    border-radius: 20px;
  }

  .icon-front-text h4 {
    font-size: 16px;
    margin-top: 24px;
  }

  .icon-front-text ul {
    font-size: 15px;
    list-style-type: none;
    padding-left: 0px;
    text-align: center;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .produkte-header h2 {
    font-size: 58px;
  }

  .produkte-header h3 {
    font-size: 22px;
  }

  .produkte-header p {
    font-size: 22px;
    padding: 14px 24px;
  }

  .icon-bohrer,
  .icon-prophy,
  .icon-poli,
  .icon-endo,
  .icon-ins,
  .icon-stif {
    height: 240px;
    width: 240px;
  }

  .icon-front-boh,
  .icon-front-pro,
  .icon-front-pol,
  .icon-front-endo,
  .icon-front-ins,
  .icon-front-stif {
    height: 240px;
    width: 240px;
    border-radius: 40px;
  }

  .icon-front-text h4 {
    font-size: 22px;
    margin-top: 40px;
  }

  .icon-front-text ul {
    font-size: 16px;
    list-style-type: none;
    padding-left: 0px;
    text-align: center;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .produkte-header h2 {
    font-size: 48px;
  }

  .produkte-header h3 {
    font-size: 20px;
  }

  .produkte-header p {
    font-size: 18px;
    padding: 14px 24px;
  }

  .icon-bohrer,
  .icon-prophy,
  .icon-poli,
  .icon-endo,
  .icon-ins,
  .icon-stif {
    height: 200px;
    width: 200px;
  }

  .icon-front-boh,
  .icon-front-pro,
  .icon-front-pol,
  .icon-front-endo,
  .icon-front-ins,
  .icon-front-stif {
    height: 200px;
    width: 200px;
    border-radius: 40px;
  }

  .icon-front-text h4 {
    font-size: 20px;
    margin-top: 30px;
  }

  .icon-front-text ul {
    font-size: 16px;
    list-style-type: none;
    padding-left: 0px;
    text-align: center;
  }
}
