.header {
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 25px;
  text-align: center;
}

.header h1 {
  font-size: 68px;
  font-weight: 500;
  padding-top: 40px;
}

.header p {
  font-size: 24px;
  font-weight: 300;
  color: #9e9e9e;
  line-height: 1.7;
  margin-bottom: 80px;
}

.header span {
  font-weight: 400;
}

@media only screen and (max-width: 480px) {
  .header {
    padding: 20px 20px;
  }

  .header h1 {
    font-size: 34px;
    margin-top: 60px;
  }

  .header p {
    font-size: 18px;
    text-align: left;
    margin-bottom: 110px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .header {
    padding: 20px 20px;
  }

  .header h1 {
    font-size: 34px;
  }

  .header p {
    font-size: 18px;
    text-align: left;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .header h1 {
    font-size: 52px;
  }

  .header p {
    font-size: 20px;
    padding: 10px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1250px) {
  .header h1 {
    font-size: 58px;
  }

  .header p {
    font-size: 22px;
    padding: 16px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px){
  .header h1 {
    font-size: 48px;
  }

  .header p {
    font-size: 18px;
    padding: 16px;
  }
}
